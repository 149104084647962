import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'

import forBizHeroImg from '../images/hero-women-flower-shopping.jpg'
import forBizSvg from '../images/icon-info.svg'
import iconInstagramCircle from '../images/icon-instagram-circle.svg'
import iconTwitterCircle from '../images/icon-twitter-circle.svg'
import iconFacebookCircle from '../images/icon-facebook-circle.svg'
import iconYouTubeCircle from '../images/icon-youtube-circle.svg'

const HowToHelpPage = () => (
  <Layout>
    <PageHelmet
      title="How to help"
      description="If you'd like to support our cause, please consider a donation to helps offset the cost of our operations."
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'How To'}
      accentedTitle={'Help'}
      subtitle={'Your support is important to our mission'}
    />

    <section className="section" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-9 center-block">
            <br />
            <br />
            <div class="columns has-text-centered">
              <div class="column">
                <h3>Donate</h3>
                <p>
                  If you'd like to support our cause, please consider a donation
                  to helps offset the cost of our operations.
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_blank"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="K4P7T56445TUG"
                  />
                  <button type="submit" className="button">
                    Donate Now
                  </button>
                  <img
                    alt="Paypal"
                    border="0"
                    src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </div>
              <div class="column">
                <h3>Follow Us</h3>
                <p>
                  <span className="accent">#</span>themaskofwellness
                  <br />
                  <span className="accent">#</span>covidconsciousbusiness
                  <br />
                  <span className="accent">#</span>maskofwellnessbusiness
                  <br />
                </p>
                <a
                  href="https://www.instagram.com/covidconsciousbusinesses/"
                  target="_blank"
                  className="footer-social-icon"
                >
                  <img src={iconInstagramCircle} />
                </a>
                <a
                  href="https://twitter.com/covidconscious"
                  target="_blank"
                  className="footer-social-icon"
                >
                  <img src={iconTwitterCircle} />
                </a>
                <a
                  href="https://www.facebook.com/covidconsciousbusinesses"
                  target="_blank"
                  className="footer-social-icon"
                >
                  <img src={iconFacebookCircle} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCnYymJ2fA1lemx5HziOuhUA"
                  target="_blank"
                  className="footer-social-icon"
                >
                  <img src={iconYouTubeCircle} />
                </a>
              </div>
              <div class="column">
                <h3>Sponsorship</h3>
                <p>
                  We're featuring businesses, organizations, and individuals for supporting our cause.
                </p>
                <a href="">
                  <a
                    href="../downloads/sponsor-sheet.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  <button type="submit" className="button">
                      Get Info
                  </button>
                  </a>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default HowToHelpPage
